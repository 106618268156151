import React, { useEffect, useState } from "react";

import api from "../../services/Api";
import { Popconfirm, Table } from "antd";

import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getUsers();
                setUsers(response);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    const toggleEmailConfirmed = async (id) => {
        try {
            await api.userAction(id, "toggleEmailConfirmed");
            const updatedUsers = users.map((user) => {
                if (user.id === id) {
                    return { ...user, emailConfirmed: !user.emailConfirmed };
                }

                return user;
            });

            setUsers(updatedUsers);
        } catch (error) {
            console.error("Error toggling email confirmed:", error);
        }
    };

    const toggleDeactivated = async (id) => {
        try {
            await api.userAction(id, "toggleDeactivated");
            const updatedUsers = users.map((user) => {
                if (user.id === id) {
                    return { ...user, deactivated: !user.deactivated };
                }

                return user;
            });

            setUsers(updatedUsers);
        } catch (error) {
            console.error("Error toggling deactivated:", error);
        }
    };

    const columns = [
        {
            title: "Nimi",
            dataIndex: "fullnameReverse",
            key: "fullname",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.fullnameReverse.localeCompare(b.fullnameReverse),
        },
        {
            title: "Sähköposti",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Puhelin",
            dataIndex: "phonenumber",
            key: "phonenumber",
        },
        {
            title: "Julkaistu",
            align: "center",
            sorter: (a, b) => (a.auctionCount?.published || 0) - (b.auctionCount?.published || 0),
            sortDirections: ['descend'],
            render: (record) => (
                <div className="flex items-center justify-center">
                    {record.auctionCount?.published && (
                        <span className="size-5 flex items-center justify-center rounded bg-green-300">
                            {record.auctionCount?.published}
                        </span>
                    )}
                </div>
            ),
        },
        {
            title: "Luonnos",
            align: "center",
            sorter: (a, b) => (a.auctionCount?.draft || 0) - (b.auctionCount?.draft || 0),
            sortDirections: ['descend'],
            render: (record) => (
                <div className="flex items-center justify-center">
                    {record.auctionCount?.draft && (
                        <span className="size-5 flex items-center justify-center rounded bg-amber-300">
                            {record.auctionCount?.draft}
                        </span>
                    )}
                </div>
            ),
        },
        {
            title: "Vahvistettu",
            dataIndex: "emailConfirmed",
            key: "emailConfirmed",
            align: "center",
            width: 50,
            render: (confirmed, record) =>
                confirmed ? (
                    <CheckCircleFilled
                        className="text-green-600 text-lg cursor-pointer"
                        onClick={() => toggleEmailConfirmed(record.id)}
                    />
                ) : (
                    <CloseCircleOutlined
                        className="text-gray-600 text-lg cursor-pointer"
                        onClick={() => toggleEmailConfirmed(record.id)}
                    />
                ),
        },
        {
            title: "Estetty",
            dataIndex: "deactivated",
            key: "deactivated",
            align: "center",
            width: 50,
            render: (deactivated, record) =>
                deactivated ? (
                    <CheckCircleFilled
                        className="text-red-600 text-lg cursor-pointer"
                        onClick={() => toggleDeactivated(record.id)}
                    />
                ) : (
                    <Popconfirm
                        title="Haluatko varmasti estää käyttäjän?"
                        onConfirm={() => toggleDeactivated(record.id)}
                    >
                        <CloseCircleOutlined className="text-gray-600 text-lg cursor-pointer" />
                    </Popconfirm>
                ),
        },
    ];

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Käyttäjät</h1>

            <div className="text-lg mb-5">Käyttäjiä: {users.length}</div>

            <Table dataSource={users} columns={columns} rowKey="id" size="small" />
        </div>
    );
};

export default Users;
